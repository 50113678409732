import moment from "moment-timezone";
import { GetLocalStore } from "./storage";
import {
  allTimeZones,
  allTimeZonesArray,
  PaymentOptionsradio,
  timeZoneLongConvertion,
  timeZoneLongShort,
} from "./form-utils";
/**
 * returns currency in us format
 * @param number 2500
 * @returns $2,500.00
 */
export const CurrencyFormat = (number: number) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(number || 0);
};

export const getTimeFormat = (date: any) => {
  const time = new Date(date);
  return time
    .toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .toUpperCase();
};
export const getDateFormatWithDay = (date: any) => {
  const time = new Date(date);
  return time.toLocaleString("en-US", { weekday: "long" });
};

export const getYearFormat = (date: any) => {
  const time = new Date(date);
  return time.toLocaleString("en-US", { year: "numeric" });
};
export const getDayFormat = (date: any) => {
  const time = new Date(date);
  return time.toLocaleString("en-US", { day: "2-digit" });
};
export const getMonthFormat = (date: any) => {
  const time = new Date(date);
  return time.toLocaleString("en-US", { month: "short" }).toLowerCase();
};
export const getWeekdayFormat = (date: any) => {
  const time = new Date(date);
  return time.toLocaleString("en-US", { weekday: "short" }).toLowerCase();
};
export const getCompleteDate = (date: any) => {
  const time = new Date(date);
  return time
    .toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    })
    .toLowerCase();
};
export const getFullDate = (date: any) => {
  const time = new Date(date);
  return time
    .toLocaleString("en-US", {
      weekday: "long",
      month: "long",
      day: "2-digit",
      year: "numeric",
    })
    .toLowerCase();
};
export const getLongDate = (date: any) => {
  const time = new Date(date);
  return time
    .toLocaleString("en-US", { month: "long", day: "2-digit", year: "numeric" })
    .toLowerCase();
};
export const getLongDateForList = (date: any) => {
  const time = new Date(date);
  return time
    .toLocaleString("en-US", { day: "2-digit", month: "long", year: "numeric" })
    .toLowerCase();
};
export const getShortDateForList = (date: any) => {
  const time = new Date(date);
  return time
    .toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .toLowerCase();
};

export const checkAccess = (featureId: any) => {
  const userSession = GetLocalStore("tenantUserSession");
  const response =
    userSession?.CurrentApplicationType === applicationType.Administrator ||
    userSession.Features[featureId] > 0;
  return response;
};

export const checkSubscriptionAccess = (featureId: any) => {
  const userSession = GetLocalStore("tenantUserSession");
  var response =
    userSession.CurrentApplicationType === applicationType.Administrator ||
    userSession.Subscriptions.includes(featureId);
  return response;
};

export const checkSubscriptionStatus = (featureId: any) => {
  const userSession = GetLocalStore("tenantUserSession");
  var response = userSession.Subscriptions.includes(featureId);
  return response;
};

export const applicationType = {
  None: 0,
  Administrator: 1,
  Client: 2,
  Associate: 8,
};

export const getBuildDate = (epoch: any) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const CapitalizeStr = (str: any) => {
  let res = str;
  if (str.length > 0) {
    res = str.charAt(0).toUpperCase() + str.slice(1);
  }
  return res;
};

export const getFullImageUrl = (imageUrlString: string) => {
  let updatedUrl = "";
  let  imageUrl=imageUrlString
  if (imageUrl) {
    if (imageUrl.includes("https://") || imageUrl.includes("base64")) {
      return imageUrl;
    }else{
      imageUrl=imageUrlString?.replace('//','/')
    }
    updatedUrl = `${process.env.REACT_APP_DOTNET_APP_URL}${imageUrl}`;
  }
  return updatedUrl;
};

export const checkIfValidUUID = (url: string) => {
  const hostValues = url.split(".");
  let tenantStatus = false;
  if (
    hostValues.length === 3 &&
    hostValues[0] !== "www" &&
    hostValues[0] !== "ui"
  ) {
    const tenantName = hostValues[0];
    const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    tenantStatus = regexExp.test(tenantName);
  }
  return tenantStatus;
};

export const calculatePackageRecurrenceDate = (
  date: any,
  repeat: any,
  type: any
) => {
  const recurrenceDate = moment(date).add(repeat, type).format("L");
  return recurrenceDate;
};

export const getStatusCount = (data: any, status: any) => {
  let statusCount = data?.filter((el: any) => el.status === status).length;
  return statusCount;
};
export const getTimeZoneById = (name: string) => {
  const timezone = Object.keys(allTimeZones).find(
    (key: string) => allTimeZones[key] === name
  );
  return timezone;
};
export const getTimeZoneShortHand = (timezone: string) => {
  const timezoneShorthand = allTimeZonesArray.filter(
    (i: any) => i?.name === timezone
  )?.[0]?.abbr;
  return timezoneShorthand;
};

export const CheckUserMemberStatus = (userData: any) => {
  if (userData?.Status === 4) {
    return false;
  }
  return true;
};

export enum PrivateServiceInstanceStatusEnum  {
  None= 0,
  Active= 1,
  Cancelled= 4,
  InActive= 5,
  Completed= 6,
};
export const PrivateServiceType = {
  Paid: 1,
  Consultation: 2,
  Complimentary: 3,
};
export const ScheduleStatus = {
  None: 0,
  Active: 1,
  Show: 2,
  NoShow: 3,
  Dispute: 4,
  Retired: 5,
};
export const PaymentMadeFor = {
  None: 0,
  Membership: 1,
  ClubService: 2,
  MembershipCancellation: 3,
  MembershipFreeze: 4,
  PrivateService: 5,
  Penalty: 6,
  ClubServiceFreeze: 7,
  ClubServiceCancellation: 9,
  PrivateServiceCancellation: 10,
  MembershipUnFreeze: 11,
  ClubServiceUnFreeze: 12,
  ClubServiceAdjustment: 14,
  PurchaseSubscription: 16,
  SubscriptionCancellation: 17,
  OneTimeCharge: 18,
  SubscriptionFreeze: 19,
  SubscriptionUnFreeze: 20,
  SubscriptionWithdraw: 21,
  ClubSubscriptionPayment: 22,
  Class: 23,
  ClassCancellation: 25,
  Package: 24,
  PackageCancellation: 25,
  PosPurchase: 27,
};

export const PackageInstanceStatus = {
  None: 0,
  Active: 1,
  InActive: 2,
  Expired: 3,
  Cancelled: 4,
  FutureNotPaid: 5,
  Future: 6,
};
export const AgreementType = {
  service: 4, //one-one-one
  plan: 1, //membership
  addon: 3, //Addon
  package: 6, //package
};

export const getPaymentOptionById = (Id: number) => {
  const paymentOptionLabel = PaymentOptionsradio.filter(
    (i: any) => i?.value === Id
  )?.[0]?.label;
  return paymentOptionLabel;
};

export const getTenantAddress = () => {
  const tenantDetails = GetLocalStore("tenantDetails");
  const address =
    (tenantDetails?.Address1 !== "" ? tenantDetails?.Address1 + ", " : "") +
    (tenantDetails?.Address2 !== "" ? tenantDetails?.Address2 + ", " : "") +
    (tenantDetails?.City !== "" ? tenantDetails?.City + ", " : "") +
    (tenantDetails?.State !== "" ? tenantDetails?.State + ", " : "") +
    (tenantDetails?.ZipCode !== "" ? tenantDetails?.ZipCode + ", " : "");
  if (address && address.length > 2) {
    return address.slice(0, address.length - 2);
  }
  return "";
};

export const checkCheckInsAccess = (featureId: any) => {
  const userSession = GetLocalStore("tenantUserSession");
  var response = userSession.Subscriptions.includes(featureId);
  return response;
};

export const getUserTimeZoneShort = (userTimeZone: any) => {
  const data = new Date()
    .toLocaleDateString(undefined, {
      day: "2-digit",
      timeZone: userTimeZone,
      timeZoneName: "long",
    })
    .substring(4)
    .match(/\b(\w)/g)
    ?.join("");
  return data
    ? timeZoneLongShort[data]
      ? timeZoneLongShort[data]
      : data
    : data;
};
export const getUserTimeZoneLong = (userTimeZone: any) => {
  const data = new Date()
    .toLocaleDateString(undefined, {
      day: "2-digit",
      timeZone: userTimeZone,
      timeZoneName: "long",
    })
    .substring(4);
  return data
    ? timeZoneLongConvertion[data]
      ? timeZoneLongConvertion[data]
      : data
    : data;
};

export const checkHasTaxEnable = () => {
  const userSession = GetLocalStore("tenantUserSession");
  const response = userSession.ClubPreferences?.HasTaxEnabled || false;
  return response;
};
export const checkIfUserCanAddBankAccount = () => {
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  if (paymentGatewaytype === 1) {
    return true;
  }
  return false;
};

export const paymentDropdownItems = [
  {
    iconClass: "fa fa-credit-card pr-1",
    text: "Add credit card",
    key: "addCard",
    visible: true,
  },
  {
    iconClass: "fa fa-university pr-1",
    text: "Add Bank Account",
    key: "addBank",
    visible: checkIfUserCanAddBankAccount(),
  },
];

const toFixedDown = (n: any, digits: number) => {
  let re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
    m = n?.toString()?.match(re);
  return m ? parseFloat(m?.[1]) : n?.valueOf();
};

export const CustomToFixed = (number: any, p: number) => {
  const n = toFixedDown(number, 3);
  const lastone = +n?.toString()?.split("")?.pop();
  if (lastone > 5) {
    return +parseFloat(n)?.toFixed(p);
  } else {
    let result = n?.toFixed(p);
    return result <= n ? +result : +(result - Math.pow(0.1, p))?.toFixed(p);
  }
};


export const checkHasSMSEnable = () => {
  const userSession = GetLocalStore("tenantUserSession");
  const response = userSession.ClubPreferences?.HasSMSNotificationEnabled || false;
  return response;
};

export const checkIsInTrailPeriod = () => {
  const userSession = GetLocalStore("tenantUserSession");
  const response = userSession.ClubPreferences?.HasClubInTrial || false;
  return response;
};

export const AdditionPaymentCreditMessage=(checkPPExists:boolean,CreditAmount:number,PurchaseAmount:number)=>{
  if(checkPPExists) {
    return `Insufficient credits (${CurrencyFormat(CreditAmount)}) for your ${CurrencyFormat(PurchaseAmount)} purchase. The below payment profile will be used for auto-fill to complete the purchase.`
  }else{
    return `Insufficient credits(${CurrencyFormat(CreditAmount)}) for your ${CurrencyFormat(PurchaseAmount)} purchase. Add a payment profile for auto-fill to complete the purchase.`
  }
}
export function parseOffsetString(offsetString:any) {
  let offsetValue=offsetString
  if(!offsetString.includes('-')){
    offsetValue=`+${offsetString}`
  }
  const [time,sign, hours, minutes] = offsetValue.match(/([+-])(\d{2}):(\d{2}):(\d{2})/) || [];
  if (sign && hours && minutes) {
    const totalMinutes = (sign === '-' ? -1 : 1) * (parseInt(hours, 10) * 60 + parseInt(minutes, 10));
    return totalMinutes;
  }
 
  return NaN; // Invalid offset string
}
export function convertToTimezone(date:any,sendDateObj=false):any {
  const tenantData = GetLocalStore("tenantUserSession");
  if(tenantData){
    const TimeZoneObj=tenantData?.TimeZone
    const TimeZoneOffSet=tenantData?.TimeZoneOffSet || TimeZoneObj?.BaseUtcOffset
    const offsetMinutes= parseOffsetString(TimeZoneOffSet)
    const utcTimestamp = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
    const targetTimestamp = utcTimestamp + offsetMinutes * 60 * 1000;
   
    const convertedDate = new Date(targetTimestamp);
    if(sendDateObj){
      return convertedDate
    }
    return moment(convertedDate).format("YYYY-MM-DDTHH:mm:ss");
  }
  if(sendDateObj){
    return new Date(moment().format("YYYY-MM-DDTHH:mm:ss"))
  }
  return moment().format("YYYY-MM-DDTHH:mm:ss");
}
 


// getTokenExpirationTime function extracts the expiration time from a JWT
export const getTokenExpirationTime = (token: string) => {
  try {
    // Decode the token to get its payload
    const payload = JSON.parse(atob(token.split('.')[1]));
    // Extract the expiration time from the payload
    const expirationTime = payload.exp * 1000; // Convert to milliseconds
    return expirationTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

// Function to check if the token is expired
export const isTokenExpired = (token: string) => {
  const expirationTime: any = getTokenExpirationTime(token); // Implement this function to extract token expiry time
  console.log(expirationTime,'expirationTime')
  return expirationTime < Date.now();
};
