import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input, Switch } from "@progress/kendo-react-inputs";
import BuzopsIntakeForm from "components/BuzopsForms/BuzopsOnlineIntakeForms/BuzopsIntakeForm";
import ViewClientResponse from "components/BuzopsForms/ViewClientResponse";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import moment from "moment";
import React, { useEffect } from "react";
import { useApiClient } from "services/axios-service-utils";
import { checkHasSMSEnable } from "utils";
import { filterBy } from "@progress/kendo-data-query";
import "../../../scss/forms/application.scss";
import {
  CommunicationPreferenceEnum,
  CommunicationPreferenceList,
  CommunicationPreferenceOnlyEmail,
  FormTemplateCategory,
  FormTemplateCategoryIntEnum,
  FormTemplateStatusIntEnum,
} from "utils/form-utils";
import useState from "react-usestateref";
import CustomCalendar, { FilterOptions } from "atoms/CustomCalendar";
import { TenantService } from "services/tenant/index.service";
import { useDispatch, useSelector } from "react-redux";
import { setEnforceStatus, setUserConfiguration } from "redux-slices/userSlice";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import useDebounce from "components/customHooks/useDebounce";
import MemberSelection from "components/submemberseletion/MemberSelection";
import ClientNameWithMemberType from "../ClientNameWithMemberType";
const DateCell = (props: any) => {
  return (
    <td>
      {props?.dataItem[props?.field]
        ? moment(props?.dataItem[props?.field]).format("L")
        : "NA"}
    </td>
  );
};
const initialDialog = {
  enforce: false,
  share: false,
};

const Forms = (props: any) => {
  const { axiosRequest } = useApiClient();
  const dispatch = useDispatch();
  const { IsEnforced = false, IsFormsEnforced = false } = useSelector(
    (state: any) => state?.userConfiguration
  );
  const [formsList, setFormsList] = useState([]);
  const [origFormsList, setOrigFormsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [forminstanceLoading, setForminstanceLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedTabItem, setSelectedTabItem] = useState<any>(0);
  const [savedFormsList, setSavedFormsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [fromDateToDate, setFromDateToDate, fromDateToDateRef] = useState({
    from: moment().startOf("year").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [clientSubmitDetails, setClientSubmitDetails] = useState<any>(null);
  const [visibility, setVisibility] = useState({
    saveResponse: false,
    viewResponse: false,
  });
  const initailDataValue = {
    CommunicationPreference:checkHasSMSEnable() ? 0 : CommunicationPreferenceEnum.Email,
    Enforce: false,
  };
  const [shareData, setShareData] = useState(initailDataValue);
  const [showDialog, setShowDialog] = useState(initialDialog);
  const debouncedValue = useDebounce(searchText, 500);
  useEffect(() => {
    fetchClientData(props?.UserMemberId);
    fetchFormsInstancesList();
  }, []);


  const fetchClientData = async (usermemberVal: any) => {
    let clientSubmitDetails = null;
    const tenantService = new TenantService();

    const res = await tenantService.GetSubMembersWithParent(props?.UserMemberId);
    let result = res?.find(
      (item: any) => item?.UserMemberId === props?.UserMemberId
    );
    if (result) {
      clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        Photo: result?.Photo,
        LastName: result?.LastName,
        FullName: result?.FullName,
        PrimaryMemberId: result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        MemberType: result?.MemberType,
        Status: result?.UserMemberStatus || result?.Status
      };
    }
    setAllClientsList(res);
    setClientSubmitDetails(clientSubmitDetails);
  };

  const toggleDialog = () => {
    setShowDialog(initialDialog);
  };

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchFormsList();
    await setLoading(false);
  };

  useEffect(() => {
    fetchFormsList();
  }, [debouncedValue]);

  const errorCallBack = (message: string) => {
    props?.handleNotificationMessage(message, "error");
  };

  const fetchFormsList = async () => {
    // need to modify paload based on API
    let req: any = {
      SortDirection: "ASC",
      SortBy: "FormName",
      PageSize: 32767,
      PageNumber: 0,
      Status: FormTemplateStatusIntEnum["PUBLISHED"],
      FormCategory: FormTemplateCategoryIntEnum["GENERAL"],
      UserId: !props?.staffPage ? props?.userDetails?.UserId : undefined,
      IsStaff: props?.staffPage,
    };
    if (searchText.length > 2) {
      req.Filters = [
        {
          Column: "FormName",
          Filter: "contains",
          DataType: "string",
          Value: searchText,
        },
      ];
    }

    await axiosRequest.post("Forms/FormTemplate/filter", req, {
      successCallBack: (response: any) => {
        const records = response.data?.Items?.filter(
          (i: any) => !i?.IsFormSubmitted
        );
        setFormsList(records || []);
        setOrigFormsList(records || []);
      },
      errorCallBack: (response: any) => {
        errorCallBack(
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Internal server error"
        );
      },
    });
  };

  const fetchFormsInstancesList = async () => {
    setForminstanceLoading(true);

    // need to modify paload
    let req: any = {
      SortDirection: "DESC",
      SortBy: "FormInstanceId",
      PageSize: 32767,
      PageNumber: 0,
      // FormTemplateId: "tbKy",
      // UserId: "tQ==",
      FromDate: fromDateToDateRef?.current?.from,
      ToDate: fromDateToDateRef?.current?.to,
      UserId: props?.userDetails?.UserId,
    };
    await axiosRequest.post("Forms/FormInstance/filter", req, {
      successCallBack: (response: any) => {
        setForminstanceLoading(false);

        const data = response.data?.Items || [];
        setSavedFormsList(data);
        // return response.data?.Items || [];
      },
      errorCallBack: (response: any) => {
        setForminstanceLoading(false);

        errorCallBack(
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Internal server error"
        );
      },
    });
    setLoading(false);
  };
  const setFromDateToDateFunc = async (val: any) => {
    setFromDateToDate(val);
    await fetchFormsInstancesList();
  };
  const handleShareForm = async () => {
    if (!shareData?.CommunicationPreference) {
      props?.handleNotificationMessage("Please Select Communication", "error");
      return;
    }
    const req = {
      FormTemplateId: selectedItem?.FormTemplateId,
      UserId: clientSubmitDetails?.UserId,
      CommunicationPreference: shareData?.CommunicationPreference,
      IsEnforce: shareData?.Enforce,
    };
    setBtnLoading(true);
    await axiosRequest.post("Forms/FormTemplate/assign", req, {
      successCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          "The form has been successfully sent to the client.",
          "success"
        );
        toggleDialog();
        setSelectedItem(null);
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Internal server error",
          "error"
        );
      },
    });
  };
  const handleEnforceClient = async () => {
    const req = {
      FormTemplateId: selectedItem?.FormTemplateId,
      UserId: props?.userDetails?.UserId,
      IsEnforce: true,
    };
    setBtnLoading(true);
    await axiosRequest.post("Forms/FormTemplate/assign", req, {
      successCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          "Form Template enforced successfully",
          "success"
        );
        toggleDialog();
        setSelectedItem(null);
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        props?.handleNotificationMessage(
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Internal server error",
          "error"
        );
      },
    });
  };

  const ViewResponseAction = (propsData: any) => {
    return (
      <td>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <BuzopsTooltip
            html={
              <BuzopsButton
                title="Saved Form"
                primary={true}
                // iconClass="fa fa-envelope"
                className="k-button-sm send-link-btn-affiliate"
                onClick={() => {
                  handleSelectForm(propsData?.dataItem);
                  handleView("viewResponse", propsData?.dataItem);
                }}
                label={"View Response"}
              />
            }
          />
        </div>
      </td>
    );
  };
  const handlePopUpWindow = (handleEvent: any) => {
    setVisibility(handleEvent);
    setSelectedItem(null);
    fetchAllApis();
  };

  const handleSelectForm = (dataItem: any) => {
    setSelectedItem(dataItem);
  };

  const filterChange = (e: any) => {
    const filteredData = filterData(e.filter);
    setFormsList(filteredData);
  };

  const filterData = (filter: any) => {
    const data = origFormsList.slice();
    return filterBy(data, filter);
  };

  const handleView = async (screen: string, item?: any) => {
    if (!item) {
      props?.handleNotificationMessage("Please select the form", "error");
      return;
    }
    setSelectedItem(item);
    setVisibility({ ...visibility, [screen]: true });
  };
  const handleViewDialog = async (screen: string, item?: any) => {
    if (!item) {
      props?.handleNotificationMessage("Please select the form", "error");
      return;
    }
    setSelectedItem(item);
    setShowDialog({ ...showDialog, [screen]: true });
  };
  // const itemRender = (
  //   li: React.ReactElement<HTMLLIElement>,
  //   itemProps: ListItemProps
  // ) => {
  //   const itemChildren = (
  //     <div className="d-flex justify-content-between">
  //       <div>{itemProps?.dataItem?.FormName}</div>
  //       {itemProps?.dataItem?.IsEnforce && IsEnforced && IsFormsEnforced && (
  //         <div>
  //           <span className="badge badge-status-Expired">Required</span>
  //         </div>
  //       )}
  //     </div>
  //   );

  //   return React.cloneElement(li, li.props, itemChildren);
  // };

  const handleChange = (val: any, name: string) => {
    setShareData({ ...shareData, [name]: val });
  };
  const fetchEnforceDetails = async (userMemberId: any) => {
    await axiosRequest.get(`authentication/GetMemberStatus/${userMemberId}`, {
      successCallBack: (response: any) => {
        const data = response.data;
        dispatch(
          setEnforceStatus({
            IsEnforced: data?.IsEnforced,
            IsCardEnforced: data?.IsCardEnforced,
            IsFormsEnforced: data?.IsFormsEnforced,
            UserMemberStatus: data?.UserMemberStatus,
          })
        );
      },
      errorCallBack: (response: any) => {
        props?.handleNotificationMessage(
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Internal server error",
          "error"
        );
      },
    });
  };

  const handleCloseSavedResponse = async () => {
    await fetchEnforceDetails(props?.userDetails?.UserMemberId);
    await props?.onSuccessfullFormSubmit();
    handlePopUpWindow({ ...visibility, saveResponse: false });
  };

  const viewResponsesList = () => {
    return (
      <>
        {/* <div className="d-flex justify-content-between">
          <h5 className="my-2 bz_fs_1_5">Saved Responses</h5>
        </div> */}
        <div className="d-flex justify-content-start mb-2 mx-2">
          <span title="filter-dates" className="mr-2 ">
            {/* <div>Select Date</div> */}
            <CustomCalendar
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              setFromDateToDate={setFromDateToDateFunc}
              selectedDateItem={FilterOptions.ThisYear}
              onlyPastDates

            />
          </span>
        </div>
        {forminstanceLoading ? <BuzopsLoader type="list" /> : <Grid data={savedFormsList || []} className="bzc-grid">
          <Column
            field="FormName"
            title="Form Name"
            cell={({ dataItem }) => {
              return <td>{dataItem?.FormTemplate?.FormName}</td>;
            }}
          />
          <Column
            field="Description"
            title="Description"
            cell={({ dataItem }) => {
              return <td>{dataItem?.FormTemplate?.Description}</td>;
            }}
          />
          <Column
            field="FormCategory"
            title="Form Category"
            cell={({ dataItem }) => {
              const FormCategory = FormTemplateCategory?.find((i: any) => i?.text === dataItem?.FormTemplate?.FormCategory)?.displayName || dataItem?.FormTemplate?.FormCategory
              return <td>{FormCategory}</td>;
            }}
          />
          <Column
            field="CreateDateTime"
            title="Submitted Date"
            cell={DateCell}
          />
          <Column
            field="ReferredBy"
            title="Referred By"
            cell={({ dataItem }) => {
              return (
                <td>
                  {dataItem?.Affiliate
                    ? `${dataItem?.User?.FirstName} ${dataItem?.User?.LastName}`
                    : `NA`}
                </td>
              );
            }}
          />
          <Column
            field="Action"
            cell={(props: any) => ViewResponseAction(props)}
          />
        </Grid>}

      </>
    );
  };

  if (visibility.saveResponse) {
    return (
      <>
        <h5 className="mb-3 bz_fs_1_5 affiliatemainheading">
          <button
            className="btn btn-primary bz-back-btn btn-sm mr-3"
            onClick={() => handleCloseSavedResponse()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;{"Fill Form"}
        </h5>
        <BuzopsIntakeForm
          FormTemplate={selectedItem}
          userDetails={props?.userDetails}
          handleWindow={() => () => handleCloseSavedResponse()}
        />
      </>
    );
  }

  if (visibility.viewResponse) {
    return (
      <>
        <h5 className="mb-3 bz_fs_1_5 affiliatemainheading">
          <button
            className="btn btn-primary bz-back-btn btn-sm mr-3"
            onClick={() =>
              handlePopUpWindow({ ...visibility, viewResponse: false })
            }
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;{"View Saved Response"}
        </h5>
        <ViewClientResponse
          formInstance={selectedItem}
          handleWindow={() =>
            handlePopUpWindow({ ...visibility, viewResponse: false })
          }
        />
      </>
    );
  }

  const NewMyItemRender = (propsData: any) => {
    let item = propsData.dataItem;
    const FormCategory = FormTemplateCategory?.find((i: any) => i?.text === item?.FormCategory)?.displayName || item?.FormCategory
    return (
      <>
        <div className="col-12 rewards">
          <div className="row bz-inhouse-group-events align-middle buz-online-list-view bz-forms-list-new formlist group-serv-list share-forms-listview p-3">
            <div className="section-1">
              <div className="section-1-items">
                <div className="bz-main-provider">
                  <div className="bz-provider-name">
                    <h6>
                      {" "}
                      <small className="bz-updated-on formlistheading">
                        {"Form Name"}
                      </small>
                    </h6>
                    <span className="bz-provider">{item?.FormName}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-2">
              <div className="section-1-items">
                <div className="bz-main-provider">
                  <div className="bz-provider-name">
                    <h6>
                      {" "}
                      <small className="bz-updated-on formlistheading">
                        {"Category"}
                      </small>
                    </h6>
                    <span className="bz-provider">{FormCategory}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-3">
              <div className="bz-main-provider">
                <div className="bz-provider-name">
                  <small className="bz-updated-on formlistheading">
                    Action
                  </small>
                  <div
                    style={{
                      display: "flex",
                      width: "300px",
                      // justifyContent: "space-between",
                    }}
                  >
                    {props?.staffPage && (
                      <>
                        <button
                          className="actiobtnform align-items-center pl-0"
                          onClick={() => handleViewDialog("share", item)}
                        >
                          {" "}
                          <i className="fa fa-share colorgreyforms  mr-2"></i>
                          <span className="ft-14"> Share</span>
                        </button>


                        {/* <div
                          onClick={() => handleViewDialog("enforce", item)}
                          style={{
                            fontSize: "14px",
                            margin: "5px",
                            cursor: "pointer",
                          }}
                        >
                          Enforce <i className="fa fa-edit"></i>
                        </div> */}
                      </>
                    )}
                    <button
                      className="actiobtnform align-items-center "
                      onClick={() => handleView("saveResponse", item)}
                    >
                      {" "}
                      <i className="fa  fa-list-alt colorgreyforms  mr-2"></i>
                      <span className="ft-14">  Fill Form</span>
                    </button>

                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleChangeClient=(value:any)=> {
    const clientDetails = {
      Email: value?.Email,
      FirstName: value?.FirstName,
      Photo: value?.Photo,
      LastName: value?.LastName,
      FullName: value?.FullName,
      PrimaryMemberId: value?.UserMemberId,
      UserId: value?.UserId,
      UserMemberId: value?.UserMemberId,
      MemberNumber: value?.MemberNumber,
      MemberType: value?.MemberType,
    };
    setClientSubmitDetails(clientDetails);
  }

  const formList = () => {
    if (loading) {
      return <BuzopsLoader type="list" />;
    }
    return (
      <>
        {formsList?.length > 0 ? (
          <>
            <div className="mt-3 bz-class-list">
              <ListView
                data={formsList || []}
                item={NewMyItemRender}
                style={{
                  width: "100%",
                }}
              // className="bz-rewards-list-view"
              />
            </div>
            <br />
          </>
        ) : (
          <div className="bz-norecords-available">{"No Forms Available"}</div>
        )}
      </>
    );
  };

  let FullName = clientSubmitDetails?.FullName
  let MemberType = clientSubmitDetails?.MemberType

  return (
    <>
      {/* {formsList?.length > 0 && (
        <>
          <div className="d-flex flex-column justify-content-start">
            {!props?.staffPage &&
              IsEnforced &&
              IsFormsEnforced &&
              EnforcedForms?.length > 0 && (
                <>
                  <div className="alert alert-warning fade show" role="alert">
                    <span>
                      {`Action Required: Fill the required form. No further actions can be performed until the form filled.`}
                    </span>
                  </div>
                </>
              )}
          </div>
        </>
      )} */}
      <TabStrip
        selected={selectedTabItem}
        onSelect={(e) => {
          setSelectedTabItem(e.selected);
        }}
        className="forms-list-tabs"
      >

        <TabStripTab title="Forms">
          <div className="d-flex justify-content-end ">

            <div className="col-md-4 col-sm-4 col-14 col-lg-4 col-xl-4 ">
              {" "}
              <div className="form-group bz-packages-search bzo-search-service mb-0 justify-content-end searchinput">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type={"search"}
                  className="form-control"
                  placeholder={"Search Form"}
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                // style={{ width: 'auto' }}
                />
              </div>
            </div>
          </div>
          {formList()}
        </TabStripTab>
        <TabStripTab title="View Responses">{viewResponsesList()}</TabStripTab>
      </TabStrip>

      {/* {popUpWindow()} */}
      {showDialog?.enforce && (
        <Dialog title={"Please confirm"} onClose={toggleDialog}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Are you sure you want to Enforce this Form?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
              disabled={btnLoading}
            >
              No
            </button>
            <BuzopsButton
              key={btnLoading?.toString()}
              onClick={() => handleEnforceClient()}
              label={"Yes"}
              className="mr-0"
              loading={btnLoading}
              disabled={btnLoading}
            />
          </DialogActionsBar>
        </Dialog>
      )}
      {showDialog?.share && (
        <Dialog
          title={"Share Form Template"}
          onClose={toggleDialog}
          width={"30%"}
        >
          <div>
            <div className="ready-to-book-appt-dialog">
              <div className="column-1-section">
                Client Name<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
                {AllClientsList?.length===1 ?  <ClientNameWithMemberType clientName={FullName} memberType={MemberType} />: <MemberSelection
                allClientsList={AllClientsList}
                selectedUsermemberId={clientSubmitDetails?.UserMemberId}
                handleUpdateClient={(val: any) => handleChangeClient(val)}
              />}
             
              </div>
              <div className="column-1-section">
                Email<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">{clientSubmitDetails?.Email}</div>
              {/* <div className="column-1-section">
                Enforce<span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
                <Switch
                  checked={shareData?.Enforce}
                  onChange={(e) => handleChange(e.value, "Enforce")}
                />
              </div> */}
              <div className="column-1-section">
                Communication
                <span className="purchased-dots">:</span>
              </div>
              <div className="column-2-section">
              {checkHasSMSEnable() ? 
                <DropDownList
                  data={
                    checkHasSMSEnable()
                      ? CommunicationPreferenceList
                      : CommunicationPreferenceOnlyEmail
                  }
                  textField="text"
                  //   className={`form-control col-md-12`}
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  name="CommunicationPreference"
                  value={
                    CommunicationPreferenceList?.filter(
                      (i: any) => i?.id === shareData?.CommunicationPreference
                    )[0] || {
                      id: 0,
                      text: "Please select",
                    }
                  }
                  onChange={(e) =>
                    handleChange(e.value.id, "CommunicationPreference")
                  }
                /> : CommunicationPreferenceList?.filter(
                  (i: any) => i?.id === shareData?.CommunicationPreference
                )[0]?.text}
              </div>
            </div>
            <div className="column-button-section book-appt-btns">
              <Button onClick={() => toggleDialog()} disabled={btnLoading}>
                {"Cancel"}
              </Button>
              <BuzopsButton
                label={"Send"}
                loading={btnLoading}
                disabled={btnLoading}
                onClick={() => handleShareForm()}
              />
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default withNotification(Forms);
